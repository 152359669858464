import React from 'react'

export default function Utilizacion() {
    return (
        <div>
            <section className="section border-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p>
                                Los compresores de aire son utilizados en una extensa variedad de industrias, desde pequeños talleres de automóviles que funcionan con compresores de pistón, hasta grandes plantas de generación de energía que utilizan un gran volumen de compresores centrífugos. 
                            </p>
                        </div>
                        <div className="col-md-12 left">
                            <p>
                                El aire comprimido se usa para muchos propósitos, que incluyen: 
                            </p>
                            <ul>
                                <li>Neumática, el uso de gases presurizados para realizar el trabajo</li>
                                    <ul>
                                        <li>Poste neumático</li>
                                        <li>Herramientas neumáticas</li>
                                        <li>Sistemas de control HVAC</li>
                                    </ul>
                                <li>Propulsión de vehículos</li>
                                <li>Almacenaje de energía</li>
                                <li>Frenos de aire, incluidos: sistemas de frenado de vehículos ferroviarios y de carretera</li>
                                <li>Buceo subacuático, para respirar y para inflar dispositivos de flotabilidad </li>
                                <li>Refrigeración mediante tubo de vórtice </li>
                                <li>Sistemas de arranque neumático en motores</li>
                                <li>Propulsión de municiones en:</li>
                                    <ul>
                                        <li>Pistolas de aire</li>
                                        <li>Equipo de airsoft</li>
                                        <li>Equipo de paintball</li>
                                    </ul>                                
                                <li>Limpieza de polvo y escombros pequeños en espacios pequeños</li>
                                <li>Arenado en talleres mecánicos</li>
                                <li>Moldeo por inyección</li>
                                <li>Aerografía utilizada por aficionados para pintar y meteorizar coches, barcos, aviones y trenes.</li>
                                <li>Tapado y fermentación de alimentos y bebidas</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
