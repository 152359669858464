import React from 'react';
import VFDChart from '../images/VFDChart.png';

export default function Elegir() {
    return (
        <div className="container">
            <section className="section border-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <p>
                                En la industria, el aire comprimido se usa tan ampliamente que a menudo se lo considera la cuarta utilidad, después de la electricidad, el gas natural y el agua. 
                            </p>
                            <p>    
                                Con el tiempo, los fabricantes han invertido en tecnología para superar las ineficiencias de los compresores de aire y simplificar la experiencia del usuario. Sin embargo, el aire comprimido sigue siendo más caro que los otros tres servicios cuando se evalúa por unidad de energía suministrada. 
                            </p>
                            <p>    
                                El desglose típico del costo del ciclo de vida de un compresor muestra que el consumo de energía representa alrededor del 88% del costo del ciclo de vida de 10 años de un compresor. Se desperdicia hasta el 50% del aire, lo que resulta en miles de euros gastados (perdidos) en energía. Hay muchos factores que contribuyen a una operación ineficiente (ver auditorías de aire). 
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className="section bg-c-light border-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h3 className="main-heading">
                                Tamaño del compresor
                            </h3>
                            <div className="underline mx-auto"></div>
                        </div>
                        <div className="col-md-12 left">
                            <p>
                                Este es un ejemplo más de que más grande no significa mejor cuando se trata de compresores de aire. Un compresor de tornillo rotativo es más eficiente cuando funciona con una carga del 100%. Cargado, significa que el compresor está produciendo aire; mientras está descargado significa que el compresor permanece inactivo. Por lo tanto, un compresor se cargará continuamente cuando su capacidad de producción de aire coincida con los requisitos de aire. 
                            </p>
                            <p>
                                Por ejemplo, si un compresor produce 15m3/min pero el requerimiento de aire es sólo 9 m3/min, entonces el compresor estará en condición de descarga durante una parte significativa del tiempo. Los compresores de tornillo son ineficientes en la condición de descarga y, por lo tanto, un compresor más grande utilizará más energía y consumibles que un compresor del tamaño adecuado para un requerimiento de aire dado. 
                            </p>
                            <p>
                                Como regla general, un compresor que solo está funcionando al 50% de su capacidad consumirá un 20% o más de energía que un compresor que esté funcionando a su máxima capacidad o cerca de ella. 
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className="section border-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h3 className="main-heading">
                                Demandas de aire fluctuantes
                            </h3>
                            <div className="underline mx-auto"></div>
                        </div>
                        <div className="col-md-12 left">
                            <p>
                                Determinar el tamaño correcto puede ser suficiente para mejorar la eficiencia energética, pero ¿qué pasa con aquellos clientes que tienen patrones de demanda variables durante el día o aquellos clientes que anticipan un aumento en la capacidad de fabricación que requiere más aire? Los compresores con variador de frecuencia (VFD) brindan soluciones para ambas situaciones. 
                            </p>
                            <p>
                                Un VFD funciona reduciendo la velocidad de rotación del motor y consecuentemente del tornillo del compresor para que el fluxo de aire producido coincida con la demanda de aire real del cliente. Al reducir la velocidad del motor, el compresor consume menos energía que un compresor de velocidad fija en comparación con el control de modulación de entrada o carga / sin carga. 
                            </p>
                        </div>
                        <div className="col-md-12 text-center">
                            <img src={VFDChart} className="w-75 border-bottom" alt="vfd" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section bg-c-light border-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h3 className="main-heading">
                                Sistema de distribución de aire
                            </h3>
                            <div className="underline mx-auto"></div>
                        </div>
                        <div className="col-md-12 left">
                            <p>
                                Cuando se busca mejorar la eficiencia energética, se debe mirar más allá del compresor de aire y evaluar el sistema de aire comprimido en su conjunto. El área más importante de mejora podría ser el sistema de distribución de la instalación: cómo se transporta y utiliza el aire comprimido en la aplicación final. Tanto el diseño de la distribución como el material utilizado para las tuberías afectan la eficiencia energética. 
                            </p>
                            <p>
                                Al diseñar un sistema de tuberías de aire comprimido, cuanto más corta sea la distancia entre el compresor y la aplicación, mejor. Minimizar las curvas en el camino a la aplicación reduce las caídas de presión y mejora la eficiencia del sistema. 
                            </p>
                            <p>
                                La elección del material para el sistema de distribución es igualmente importante. El plástico, el aluminio y el acero son los materiales más comunes para las tuberías de aire comprimido. Mientras que el acero es más accesible que el aluminio, el aluminio ofrece menor peso y resistencia a la corrosión. El aire comprimido contiene aceite y agua, lo que puede causar corrosión en la línea y, a su vez, provoca caídas de presión y afecta la eficiencia. 
                            </p>
                            <p>
                                Además, las líneas de aire comprimido son dinámicas porque vibran constantemente debido al paso del aire comprimido. Con el tiempo, estos movimientos debilitan las articulaciones y pueden provocar fugas. Las fugas encontradas en el sistema de compresores de aire de una instalación pueden desperdiciar hasta el 25% del costo del aire comprimido, lo que representa miles de euros en electricidad al año. 
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section border-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h3 className="main-heading">
                                Mantenimiento
                            </h3>
                            <div className="underline mx-auto"></div>
                        </div>
                        <div className="col-md-12 left">
                            <p>
                                Para evitar fallas, se debe seguir el mantenimiento preventivo. 
                            </p>
                            <p>
                                El costo de mantenimiento no es el más importante, pero un servicio al cliente no expedito puede detener su línea de producción por un tiempo considerable y costarle miles o millones de euros de pérdidas por falta de producción (línea de producción parada). 
                            </p>
                            <p>
                                Los sistemas de aire comprimido son responsables de una gran parte del consumo de energía en la industria, además de jugar un papel crítico en el desempeño de las líneas de producción. Por eso, ante la duda sobre qué compresor comprar, no basta con considerar únicamente especificaciones técnicas: es necesario buscar proveedores confiables, capaces de ofrecer el soporte que su operación necesita.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}
