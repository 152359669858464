import React from 'react'
import Slider from '../inc/Slider';
import Utilizacion from './inc/Utilizacion';
import Aplicacion from './inc/Aplicacion';

export default function Home() {
    return (
        <div>

            <Utilizacion />

            <Slider />

            <Aplicacion />

        </div>
    )
}
